import logo from './logo.png';
import './App.css';

import React, {useState, useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch,
  useHistory
} from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

import ENDPOINTS from './endpoint';

import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  input: {
    color: "#FFF",
    backgroundColor: "#202020",
    border: " 1px solid #FFF",
  },
}));

function App() {
  const classes = useStyles();
  const history = useHistory();


  const [loading, setLoading] = useState(false)
  const [textInput, setTextInput] = useState('');
  const handleTextInputChange = event => {
    setTextInput(event.target.value);
  };

  //let { path, url } = useRouteMatch();

  useEffect(() => {
    setLoading(false)
  },[])

  useEffect(() => {
    
    window.addEventListener('keydown', KeyPress)


    function KeyPress(e){

      if(e.key == 'Enter'){ 
        console.log(textInput)
        window.location.href = `/${textInput}`
        console.log('test')

      }
    }


    return () => window.removeEventListener('keydown', KeyPress)
  },[textInput])



  const [err, setErr] = useState({
        state: false,
        close: (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }
            setErr({...err, state: false})
        },
        type: 'warning',
        message: ''
  })


  const SearchButton = () => (
    <div onClick={() => {
      if(textInput){
        setLoading(true)
      }
    }}>
      <Link to={`/${textInput}`}>
        <IconButton>
          <SearchIcon style={{color: "#FFF"}} />
        </IconButton>
      </Link>
    </div>
    )

  return (
    <>
      <Snackbar open={err.state} autoHideDuration={5000} onClose={err.close}>
        <Alert onClose={err.close} severity={err.type}>
          {err.message}
        </Alert>
      </Snackbar>
      <div style={{minHeight: "100vh", minWidth: "100vw", backgroundColor: "#202020"}}>
        <Router>
          <div style={{minHeight: "15vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <TextField
            id="standard-name"
            //label="Card ID"
            placeholder="Card ID"
            value={textInput}
            variant="outlined"
            InputProps={{endAdornment: <SearchButton />, startAdornment: <div style={{padding: "6px 15px 5px 0px"}}><img src={logo} height={"30px"} /></div>, className: classes.input}}
            onChange={handleTextInputChange}
          />
          </div>
          <Switch>
            <Route exact path='/'><Landing /></Route>
            <Route path='/:id'><Item setLoading={setLoading} setErr={setErr} err={err} /></Route>
          </Switch>
        </Router>
      </div>
    {loading && (
      <div className={classes.root} style={{position: "fixed", width: "100vw", height: "100vh", top: "0", left: "0", display: "flex", justifyContent: "center", alignItems: "center", zIndex: "1000"}}>
        <CircularProgress style={{color: "#ffffff"}} size="80px" />
      </div>
    )}
  </>
  );
}

function Landing(){
  const mobile = !useMediaQuery('(min-width:600px)');
  const [cards, setCards] = useState([])
  const [cardRenders, setCardRenders] = useState(20)
  const loadMoreCounter = 5
  useEffect(() => {
    async function t(){
      let d = await (await fetch(`${ENDPOINTS.host}/cards`)).json()
      setCards(d.data.filter(a => a.id.includes("9271")))
      console.log(d.data)

    }
    t()
  }, [])



  return (<>
      <div style={{
        display: "flex", 
        justifyContent: "flex-start", 
        alignItems: "center", 
        width: "100%", 
        fontFamily: "Arial",
        flexDirection: "column"
        
        }}>
          <div style={{
            display: "flex", 
            justifyContent: "center", 
            alignItems: "flex-start", 
            color: "#FFF", 
            width: mobile?"90%":"70%", 
            fontFamily: "Arial",
            flexWrap: "wrap"
          }}>
          {
            cards.slice(0,cardRenders).map(a => <>
              <div style={{margin: "10px"}} onClick={() => window.location.href = `/${a.id}`}>
                <img style={{height :"250px", width: "250px", cursor: "pointer"}} src={`${ENDPOINTS.image_cloud_preview}${a.id}_front_250x250.jpg?alt=media`} />
              </div>
            </>)
          }
          </div>
        <div 
        onClick={() => {
          let n = cardRenders + loadMoreCounter
          if(n >= cards.length)n=cards.length

          setCardRenders(n)
        }}
        style={{margin: "20px 0px 40px 0px", color: "#fff", cursor: "pointer", border: "1px solid #fff", padding: "6px", borderRadius: "8px", fontSize: "18px"}}>Load more</div>
      </div> 
  </>)
}

function Item({ setLoading, setErr, err }){
  let { id } = useParams();

  let [data, setData] = useState(null)

  const margin = "4px 0px 4px 0px"

  useEffect(async () => {
    try {
      let res = await (await fetch(`${ENDPOINTS.host}/card/${id}`)).json()
      setLoading(false)
      console.log(res)
      if(!res.data && res.error){
        setData(null)
        setErr({
          ...err,
          message: res.error,
          state: true
        })
        return
      }
      setData(res.data)
      console.log(res)
    } catch (error) {
      setData(null)
      setLoading(false)
      setErr({
        ...err,
        message: error.toString(),
        state: true
      })
      
    }
  }, [id])
  return (<>
    {data && (
    <div >
      <div style={{display: "flex", justifyContent: "center", alignItems: "flex-start", color: "#FFF", minWidth: "300px", fontFamily: "Arial"}}>
        <div>
          <div style={{margin: margin}}>ID</div>
          <div style={{margin: margin}}>Cardname</div>
          <div style={{margin: margin}}>Condition</div>
          <div style={{margin: margin}}>Price (EUR)</div>
        </div>
        <div style={{height: "0px", width: "30px"}}></div>
        <div>
          <div style={{margin: margin}}>
            {data.id}
          </div>
          <div style={{margin: margin}}>
            {data.name}
          </div>
          <div style={{margin: margin}}>
            {data.condition}
          </div>
          <div style={{margin: margin}}>
            {data.price} €
          </div>
        </div>
        
      </div>
      <div style={{display: "flex", justifyContent: "center", alignItems: "flex-start", color: "#FFF", minWidth: "300px", fontFamily: "Arial", gap: "10px"}}>
        {data.images.map(image => <img className="img" src={`${ENDPOINTS.image_cloud}/${image}?alt=media`} />)} {/*${image} */}
      </div>
      <div style={{height: "30px", width: "0px"}}></div>
      {data.video.length > 10 &&
      <div style={{display: "flex", justifyContent: "center", alignItems: "flex-start", color: "#FFF", minWidth: "300px", fontFamily: "Arial"}}>
        <video height="300px" controls autoPlay muted loop src={`${ENDPOINTS.image_cloud}/${data.video}?alt=media`} />
      </div>

      }

    </div>
    )}
  </>)
}

export default App;
